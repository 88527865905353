@import "../assets/variable/Variable.scss";
.loginScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(../../public/images/banner2.jpg);
  .login {
    width: 890px;
    height: 480px;
    background-color: #fff;
    box-shadow: 0px 2px 30px rgba(204, 204, 204, 0.4);
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .lLeft {
      width: 50%;
      padding: 0px 40px;
      height: 100%;
      border-right: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .logo {
        padding-bottom: 20px;
        figure {
          width: 190px;
          margin: auto;
        }
      }
      .title {
        padding-top: 20px;
        padding-bottom: 40px;
        h2 {
          font-size: 21px;
          font-weight: 600;
          span {
            display: block;
            color: $para;
            font-size: 15px;
          }
        }
      }
      .authForm {
        .formControl {
          margin-bottom: 20px;
          input {
            font-size: 14px;
            padding: 12px;
          }
        }
        .checkbox {
          display: flex;
          align-items: center;
        }
        .saveBtn {
          padding-top: 10px;
          padding-bottom: 30px;
          button {
            background: grey;
            color: #fff;
            border: none;
            padding: 12px;
            border-radius: 4px;
            width: 100%;
            font-size: 14px;
            &:hover {
              background: $themeColor;
            }
          }
        }
        .forgot {
          h5 {
            font-size: 15px;
            cursor: pointer;
            color: $themeColor;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
    .right {
      width: 35%;
      padding: 0 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url(../../public/images/banner.jpg);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

$themeColor: linear-gradient(
  329deg,
  rgba(214, 255, 55, 1) 0%,
  rgba(127, 255, 126, 1) 62%,
  rgba(32, 255, 204, 1) 100%,
  rgba(207, 125, 209, 1) 100%,
  rgba(165, 55, 55, 1) 100%,
  rgba(156, 52, 61, 1) 100%
);
$secondary: grey;
$para: #777;

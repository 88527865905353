@import "../assets/variable/Variable.scss";
.layout {
  height: 100vh;
  .logo {
    position: relative;
    z-index: 1;
    figure {
      width: 250px;
    }
  }
  aside {
    width: 7%;
    padding: 0 10px;
    box-sizing: border-box;
    box-shadow: 0px 2px 30px rgba(204, 204, 204, 0.4);
    background: url(../../public/images/banner2.jpg);
    // background: $themeColor;
    height: 100vh;
    z-index: 10;
    position: fixed;
    &::after {
      content: "";
      position: absolute;
      background: rgb(0 0 0 / 59%);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    ul {
      padding-top: 40px;
      position: fixed;
      z-index: 1;
      li {
        padding-bottom: 20px;
        cursor: pointer;
        .sideText {
          &:hover {
            color: $themeColor;
          }
          color: #fff;
          text-align: center;
          .sIcon {
            svg {
              width: 18px;
            }
          }
          .sTxt {
            h5 {
              font-size: 13px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .lRight {
    width: 100%;
    display: flex;
    padding-top: 60px;
    height: 100%;
  }
  .content {
    width: 92%;
    padding: 20px;
    box-sizing: border-box;
    margin-left: 90px;
    .table-responsive {
      width: 100%;
      overflow: auto;
      background: #fff;
    }
  }
}
.header {
  background: #fff;
  width: 100%;
  box-shadow: 0px 2px 30px rgba(204, 204, 204, 0.4);
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  position: fixed;
  z-index: 2;
  justify-content: space-between;
  .profile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    figure {
      width: 30px;
      height: 30px;
      border-radius: 100px;
      overflow: hidden;
      margin: 0 0 0 auto;
      border: 1px solid #ddd;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    h5 {
      font-size: 14px;
      padding-left: 10px;
    }
    .logout {
      padding-left: 10px;
      display: flex;
      &:hover {
        svg {
          fill: $themeColor;
        }
      }
    }
  }
}

//logout modal

.logout_modal {
  h3 {
    font-size: 18px;
    text-align: center;
    padding-bottom: 22px;
  }
  .btn_div {
    display: flex;
    width: 40%;
    margin: auto;
    justify-content: space-between;
    .btn {
      background: $themeColor;
      padding: 10px;
      width: 45%;
    }
    .out-btn {
      background: $secondary;
      padding: 10px;
      width: 45%;
    }
  }
}
.noData {
  padding: 20px;
  figure {
    width: 230px;
    margin: auto;
  }
  p {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
}

@import "./assets/variable/Variable.scss";
body {
  margin: 0 auto;
  font-family: "Lato", sans-serif;
  background: #f1f5f7;
}
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p {
  margin: 0;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
img {
  width: 100%;
}
a {
  text-decoration: none !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: $themeColor !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: $themeColor !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: $themeColor !important;
  background-color: #fff;
}
.css-10d9dml-MuiTabs-indicator {
  background-color: $themeColor !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  align-items: flex-start !important;
  text-align: left !important;
}
.css-35zqo5-MuiTabs-root {
  width: 17% !important;
}
.mainTab {
  width: 83% !important;
}
.css-1n3h4wo {
  height: 350px !important;
}
.ck.ck-editor__main > .ck-editor__editable {
  height: 220px;
  margin-bottom: 20px;
}
::placeholder {
  font-size: 14px;
}
.center {
  text-align: center;
}
.rdt {
  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #777;
    outline: none;
  }
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 20px;
  border: 3px solid #fff;
}

.css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: $themeColor !important;
}

.right {
  display: flex;
  justify-content: flex-end;
}
td .css-1yxmbwk {
  padding: 0 !important;
  align-items: flex-start !important;
}
.css-1q2h7u5.Mui-selected {
  color: #000 !important;
}
.css-1nr2wod.Mui-checked {
  color: $themeColor !important;
}
.css-1nr2wod.Mui-checked + .MuiSwitch-track {
  background-color: $themeColor !important;
}
.formControl.datePicker 
 {.MuiStack-root{
  border-bottom: 1px solid black;
  input{
    padding: 0px 14px 14px 0px !important;
  }}
}
.text_field p{
  color: red;
  font-size: 14px;
  margin-left: 0 !important;
}
.errorMsg {
  color: red;
  font-size: 14px;
  margin-left: 0 ;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;
  border: none;
  figure {
    width: 100px;
    height: 100px; 
  }
}
.MuiTabs-flexContainer.MuiTabs-flexContainerVertical.css-lfwcke-MuiTabs-flexContainer button {
  text-align: left !important;
  align-items: flex-start !important;
  background: transparent !important;
}

.react-tel-input{
  input{
    width: 100% !important;
    border: none !important;
    border-bottom: 1px solid #0000006e !important;
    border-radius: 0 !important;
    height: 33px !important;
  }
}
.react-tel-input .flag-dropdown, .flag-dropdown .phoneBtn {
  background-color: white !important;
  border: 1px solid white !important;
  border-bottom: 1px solid #0000006e   !important;
}

//pagination
.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    width: 100%;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'regular';
    font-size: 18px;
}
  p {
    font-size: 14px;
    font-family: "regular";
    span {
      
    font-family: "bold";
    font-size: 16px;
    color: #777;

    }
  }
  .pages {
    display: flex;
    .prevbtn{
      background: no-repeat;
    border: none;
    padding: 0;
    }
    svg {
      font-size: 12px;
      border: 0.5px solid #a5a5a5;
      padding: 6px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-right: 10px;
      cursor: pointer;
      color: black;
    }
    .count {
      display: flex;
      align-items: center;
      margin-right: 10px;
      background: none;
      /* padding: 6px; */
      border-radius: 24px;
      border: 0.5px solid $themeColor;
      margin-right: 0;
      .actv {
        background: $themeColor;
        color: white;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 0.5px solid $themeColor;
        cursor: pointer;
        margin-right: 10px;

      }
      .inActv {
        background: #ddd;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
        border: none;
        margin-right: 10px;
      }
    }
  }
}

//search bar
.search_bar {
  width: 260px;
  position: relative;

  .search_icon {
    position: absolute;
    width: auto;
    height: auto;
    margin: 0;
    max-height: initial;
    color: #999;
    left: 10px;
  }


}

.search_bar .MuiInputBase-input{
  height: auto;
  padding: 10px 10px 10px 26px;
}
h3.addtime {
  cursor: pointer;
  color: $themeColor !important;
}
.uploadder{
  position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
}
#header-bar{
  &>div{
    display: none;
  }
}
.dateTimeValue input {
  border-bottom: 1px solid #777;
  padding: 20px 0px 5px 0px;
}
.slick-track{
  z-index: 10;
}
.faq .css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  width: 100%;
}
.slick-list{
  width: 90%;
  margin: auto;
}
.slick-prev:before, .slick-next:before{
  font-size: 30px;
}
.slick-prev, .slick-next{
  width: 40px;
    height: 40px;
}
@import "../assets/variable/Variable.scss";
@import "react-datetime/css/react-datetime.css";

.titleBar {
  background: #fff;
  h2 {
    font-size: 18px;
  }
}
/*table scss*/
table {
  width: 100%;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  border-spacing: 0;
  th {
    color: #000;
    font-weight: 600;
    font-size: 13px;
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
    white-space: nowrap;
    position: sticky;
    top: -1px;
    background: #fff;
    z-index: 1;
  }
  td {
    color: #777;
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13px;
    padding: 10px;
    text-align: left;
    border: 1px solid #dddddd82;
    white-space: nowrap;
    &.bigNote {
      white-space: unset;
      p {
        width: 200px;
        max-height: 100px;
        line-height: 20px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -ms-box-orient: vertical;
        box-orient: vertical;
        -webkit-line-clamp: 2;
        -moz-line-clamp: 2;
        -ms-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;
      }
    }
    &.center {
      text-align: center;
    }
    p {
      padding-bottom: 4px;
      &:last-child {
        padding-bottom: 0;
      }
      strong {
        color: #000;
      }
    }
    svg {
      font-size: 20px !important;
      cursor: pointer;
      padding-left: 5px;
      &:hover {
        fill: #000;
      }
    }
  }
}

/*button start*/
.btnRow {
  display: flex;
  gap: 8px;
}
button {
  background: $secondary;
  border: none;
  color: #fff;
  padding: 4px 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: $themeColor;
  }
  &:disabled {
    background: #ddd;
    cursor: not-allowed;
    &:hover {
      background: #ddd;
    }
  }
}
td {
  button {
    &:hover {
      background: transparent !important;
    }
  }
}
//modal
.crossBtn {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  right: 10px;
  svg {
    font-size: 20px;
    cursor: pointer;
    border-radius: 100%;
    background: $themeColor;
    padding: 3px;
  }
}

.menuModal {
  h2 {
    font-size: 16px;
    text-align: center;
    padding-bottom: 16px;
    color: grey;
  }
  .noBanners {
    figure {
      height: 180px;
    }
    p {
      text-align: center;
      font-size: 14px;
      color: #777;
    }
  }
  figure {
    height: 220px;
    width: 100%;
    margin: auto;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  button.slick-arrow.slick-next,
  button.slick-arrow.slick-prev {
    &::before {
      color: #6eff8b !important;
    }
  }
  .slick-prev {
    left: 0 !important;
  }
  .slick-next {
    right: 0 !important;
  }
  ul.slick-dots.slick-thumb {
    // position: absolute !important;
    // bottom: -70px !important;
    li {
      width: 40px !important;
      height: 40px !important;
      margin-top: 50px !important;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

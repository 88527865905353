@import "../assets/variable/Variable.scss";
// profile
.uploader {
  position: absolute;
  margin: auto;
  text-align: center;
  bottom: -14px;
  left: 0;
  right: 0;
  background: $themeColor;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid #fff;
}
.pHead {
  background: $themeColor;
  padding: 20px;
  height: 200px;
  .upload {
    .pImg {
      position: relative;
    }
  }

  figure {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin: auto;
    overflow: hidden;
    outline: 3px solid #ddd;
    background: #000;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  h4 {
    text-align: center;
    padding: 30px 0 12px;
    color: #000;
  }
}
.bio {
  display: flex;
  justify-content: space-between;
  .bMain {
    display: flex;
    width: 50%;
    align-items: center;
    .bLeft {
      padding-right: 5px;
      line-height: 0;
      svg {
        fill: $secondary;
      }
    }
    .bRight {
      h4 {
        color: #777;
        font-weight: normal;
        font-size: 15px;
      }
    }
  }
}
//cms
.cms {
 background: #fff;
 .faqic{
  background: $themeColor;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
  svg{
    color: #fff;
    width: 25px;
    
  }
}
.deleteIc{
  position: absolute;
  right: 0;
  bottom: 0;
}

.headerMain{
  display: flex;
  width: 100%;
  justify-content: space-between;
  svg{
    color: red;
    width: 18px;
  }
}
  .pdfUploader {
    height: 100%;
    position: relative;
    .pdfAdd{
      text-align: center;
    cursor: pointer;
    box-shadow: 0px 2px 30px rgba(204, 204, 204, 0.61);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin: 0 0 0 auto;
    background: $themeColor;
    position: fixed;
    right: 30px;
    bottom: 30px;
    svg{
      color: #fff;
      position: absolute;
      top: 8px;
      width: 100%;
      left: 0;
  
    }
    }
    .addOption {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        fill: #777;
      }
      p {
        color: #777;
        font-size: 15px;
        padding-top: 10px;
      }
      input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }
}
.formArea {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
  .formControl {
    width: 49%;
    margin-bottom: 20px;
    &.datePicker {
      input {
        padding-top: 0 !important;
      }
    }
    label {
      font-size: 14px;
    }
  }
}
//form
.addData {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  .titleBar{
    padding-bottom: 20px;
  }
  .formGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;
    .isTicket {
      display: flex;
      width: 49%;
      justify-content: space-between;
      .formControl {
        width: 32%;
        margin-bottom: 0;
      }
    }
    .formControl {
      width: 49%;
      margin-bottom: 20px;
      label {
        font-size: 14px;
        margin-bottom: 15px;
        display: block;
        &.mb0 {
          margin-bottom: 0;
        }
      }
      .uploadImg {
        .bussinessimg {
          ul {
            li {
              width: 100%;
            }
          }
        }
      }
      ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
          margin-bottom: 10px;
          margin-right: 15px;
          position: relative;
          .uploaded {
            width: 100%;
            height: 100%;
            // svg {
            //   color: red;
            //   position: absolute;
            //   right: 0;
            //   font-size: 20px;
            // }
            figure {
              width: 100%;
              height: 110px;
              border-radius: 8px;
              overflow: hidden;
              img {
                height: 100%;
              }
            }
          }
        }
      }
      .MuiStack-root {
        border-bottom: 1px solid black;
        input {
          padding: 14px 14px 14px 0px !important;
          color: #777;
          font-weight: normal;
          font-size: 15px;
        }
      }
      label.Mui-focused,
      label.MuiFormLabel-filled {
        display: none !important;
      }
      label#demo-simple-select-standard-label {
        left: -12px !important;
      }
      .upload {
        cursor: pointer;
        background: #ddd;
        display: flex;
        justify-content: center;
        height: 100px;
        align-items: center;
        border-radius: 10px;
        svg {
          font-size: 40px;
        }
      }
      .uploadedImg {
        height: 140px;
        position: relative;
        svg {
          position: absolute;
          right: 10px;
          cursor: pointer;
          border-radius: 100%;
          font-size: 16px;
          top: 7px;
          background: red;
          color: #fff;
          box-shadow: 0px 2px 30px rgba(204, 204, 204, 0.4);
        }
        figure {
          height: 100%;
          width: 100%;
          img {
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
          }
        }
      }
      textarea {
        overflow: hidden;
        width: 100%;
        background: none;
        border-top: none;
        border-left: none;
        border-right: none;
        resize: none;
        height: 27px !important;
        font-family: "Lato", sans-serif;
        outline: none;
      }
    }
  }
}
.rightSide {
  position: fixed;
  z-index: 10;
  top: 50%;
  right: 0;
  button {
    width: 40px;
    height: 40px;
    border-radius: 50px 0px 0px 50px;
    font-size: 30px;
    z-index: 1;
    position: relative;
    background: $themeColor;
  }
}
//seting
.bubbles {
  display: flex;
  flex-direction: column;
  .bLeft {
    padding-bottom: 20px;
    ul {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      li {
        background: #ddd;
        padding: 3px 6px 3px 20px;
        border-radius: 100px;
        font-size: 14px;
        text-transform: capitalize;
        box-shadow: 0px 2px 30px rgba(204, 204, 204, 0.4);
        position: relative;
        min-width: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          padding-right: 10px;
        }
        .deleteItem {
          display: flex;
          align-items: center;
          svg {
            width: 16px;
            height: 16px;
            cursor: pointer;
            padding-left: 2px;
          }
        }
      }
    }
  }
  .bRight {
    position: absolute;
    right: 30px;
    bottom: 60px;
    width: 350px;
    .closeModal {
      position: absolute;
      right: -3px;
      top: -6px;
      svg {
        border: 2px solid red;
        padding: 2px;
        border-radius: 100px;
        width: 15px;
        height: 15px;
        color: #fff;
        cursor: pointer;
        background: red;
      }
    }
    .formControl {
      width: 100%;
      // display: flex;
      // position: relative;
      box-shadow: 0px 2px 30px rgb(204 204 204 / 61%);
      padding: 20px;
      border-radius: 13px;
      background: #fff;
      box-sizing: border-box;
      // flex-direction: column;
      // button {
      //   position: absolute;
      //   right: 0;
      // }
      button {
        margin-top: 10px;
      }
    }

    .addOption {
      text-align: center;
      cursor: pointer;
      box-shadow: 0px 2px 30px rgba(204, 204, 204, 0.61);
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      margin: 0 0 0 auto;
      background: $themeColor;
      svg {
        color: #fff;
      }
      p {
        color: #777;
        font-size: 16px;
      }
    }
  }
}
.extraOption {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .tMain {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
    width: 100%;
    .ticketDetail {
      box-shadow: 0px 2px 30px rgba(204, 204, 204, 0.4);
    padding: 20px;
    background: #fff;
    width: 28%;
    margin-right: 10px;
    position: relative;
    margin-bottom: 10px;
    .actions{
      position: absolute;
      top: 10px;
      right: 10px;
      svg{
        width: 16px;
        height: 16px;
        margin-left: 5px;
        cursor: pointer;
        padding: 4px;
        border-radius: 100px;
        color: #fff;
        &.green{
          background: green;
        }
        &.red{
          background: red;
        }
      }
    }
      .closeIc{
        position: absolute;
        right: -6px;
        top: -8px;
        background: red;
        color: #fff;
        width: 18px;
        height: 18px;
        border-radius: 100px;
        cursor: pointer;
      }
      .tInner{
          p {
            font-size: 14px;
            padding-bottom: 10px;
            &:last-child{
              padding-bottom: 0;
            }
          }
      }
  }
}
  .guestRow {
    width: 100%;
    box-shadow: 0px 2px 30px rgba(204, 204, 204, 0.4);
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 25px;
    h3 {
      font-size: 18px;
    }
    .fGrp {
      padding-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
.addData fieldset {
  display: none !important;
}
.vTiming {
  display: flex;
  justify-content: space-between;
  .formControl {
    & > div {
      display: flex;
      justify-content: space-between;
      padding-top: 0px !important;
      width: 100% !important;
    }
  }
}
.locationMain {
  position: relative;
  button {
    position: absolute;
    right: 0;
  }
}
ul.locationBubble {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
  li {
    background: #ddd;
    padding: 8px 20px;
    border-radius: 100px;
    font-size: 14px;
    text-transform: capitalize;
    box-shadow: 0px 2px 30px rgba(204, 204, 204, 0.4);
    position: relative;
    min-width: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    p {
      padding-right: 10px;
    }
    span {
      // position: absolute;
      // right: 6px;
      // top: 9px;
      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
        padding-left: 2px;
      }
    }
  }
}

.centerCls {
  th {
    &:last-child {
      text-align: center;
    }
  }
}
.pauseBooking {
  svg {
    font-size: 24px !important;
  }
}

.search {
  background: #fff;
  padding: 10px;
  .formControl {
    width: 30%;
    margin: 0 0 0 auto;
  }
}

.formControl.datePicker {
  .MuiStack-root {
    border-bottom: 1px solid black;
    input {
      padding: 0px 14px 14px 0px !important;
    }
  }
}
.location_div {
  width: 49%;
  display: flex;
  justify-content: space-between;
}
.addSlot {
  margin-top: 20px;
}
.uploadImg {
  position: relative;
  input {
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    left: 0;
  }
}

.imgMain {
  display: flex;
  margin-bottom: 15px;
  li {
    width: 29%;
    margin-right: 15px;
    border: 1px solid #ddd;
    .uploaded {
      width: 100%;
      position: relative;
      figure {
        height: 100px;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
      .close {
        color: #fff;
        position: absolute;
        cursor: pointer;
        right: 7px;
        top: 6px;
        box-shadow: 0px 2px 30px rgba(204, 204, 204, 0.4);
        border-radius: 100px;
        font-size: 14px !important;
        padding: 1px;
        background: red;
      }
    }
  }
}

.form-control {
  border: none;
  width: 100%;
  border-bottom: 1px solid #777;
  border-radius: 0;
}
h4.addLocation {
  background: #808080;
  display: block;
  width: 40px;
  padding: 5px;
  text-align: center;
  cursor: pointer;  
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 13px;
  color: #fff;
}

.locationCity {
  .right {
    position: absolute;
    right: 50px;
    bottom: 50px;
    // width: 350px;
  }
  .addOption {
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 2px 30px rgba(204, 204, 204, 0.61);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin: 0 0 0 auto;
    background: linear-gradient(329deg, rgb(214, 255, 55) 0%, rgb(127, 255, 126) 62%, rgb(32, 255, 204) 100%, rgb(207, 125, 209) 100%, rgb(165, 55, 55) 100%, rgb(156, 52, 61) 100%);
    svg {
      color: #fff;
    }
  }
}
.locationCityVal {
  background: #ddd;
  margin-right: 7px;
  padding: 3px 10px;
  border-radius: 100px;
  float: left;
}
.serachMain{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 15px 15px 0;
}
.venueSelect .MuiSelect-select.MuiSelect-outlined{
  padding: 20px 23px 5px 0px;
    border-bottom: 1px solid #777;
    border-radius: 0;
}
.banners{
  .activeBanner{
    figure{
      width: 100%;
      height: 450px;
    }
  }
  .listbanner{
    display: flex;
    margin-top: 6px;
    li{
      width: 16.5%;
      position: relative;
      margin-right: 6px;
      &:last-child{
        margin-right: 0;
      }
    }
    figure{
      width: 100%;
      height: 100px;
    }
    .close{
      position: absolute;
      top: 6px;
      z-index: 1;
      right: 6px;
    svg{
      background: red;
      fill: #fff;
      width: 17px;
      height: 17px;
      border-radius: 100px;
      cursor: pointer;
    }
    }
  }
  img{
      object-fit: cover;
      height: 100%;
    }
  
}
.search_bar{
  svg{
    cursor: pointer;
  }
}
.rightBtn{
  text-align: right;
}
.faq{
  .fmain{
    display: flex;
    justify-content: space-between;
.fLeft {
  width: 94%;
}
  }
  .faqIcon{
  display: flex;
    svg{
      color: red;
      width: 20px;
      padding-left: 5px;
      cursor: pointer;
    }
  }
}